<template>
    <div class="box" :style="bg">
        <div class="header">
            <p>
                <img src="@/assets/images/today/danghui2.png" alt="">
            </p>
            <p class="today">党史上的今天</p>
        </div>
        <div class="content">
            <div>
                 <div class="blcok-box" v-for="item in historyList" :key="item.id">
          <div class="block-line">
            <div class="danghui">
              <img src="@/assets/images/today/danghui.png" alt="" />
            </div>
            <div class="line"></div>
          </div>
          <div class="block">
            <p class="year">{{ item.yearDate }}</p>
            <div class="blcok-content">
              <p class="blcok-content-title">{{ item.title }}</p>
              <div class="blcok-content-context" v-html="item.text"></div>
            </div>
          </div>
        </div>
            </div>
        </div>
        <div class="calendar">
            <div class="calendar-box">
                <img src="@/assets/images/today/calendar.png" alt="">
                <div class="time">
                    <p class="month">{{month}}月</p>
                    <p class="day">{{day}}日</p>
                </div>
            </div>
        </div>
        <back :pageDepth="2"></back>
    </div>
</template>

<script>
  import back from "@/components/back";
  

  const blueBg = require("@/assets/images/base/bg-blue.png");
  const redBg = require("@/assets/images/base/bg.png");
  import { mapGetters } from "vuex";
  export default {
    name: "index",
    components: {
      back
    },
    data(){
    return {
      bg: {
        backgroundImage: "url(" + redBg + ")",
      },
      bgRed: {
        backgroundImage: "url(" + redBg + ")",
      },
      bgBlue: {
        backgroundImage: "url(" + blueBg + ")",
      },
      month: "",
      day: "",
      historyList: [],
    };
  },
    computed: {
      ...mapGetters("skin", ["useSubject"])
    },
    created() {
    if (this.useSubject === "red") {
      this.bg = this.bgRed;
    } else {
      this.bg = this.bgBlue;
    }
    this.getHistoryInfo();
  },
    watch: {
      useSubject(val) {
        if (val === "red") {
          this.bg = this.bgRed;
        } else {
          this.bg = this.bgBlue;
        }
      }
    },
      methods: {
    getHistoryInfo() {
      this.POST(this.API_MODULE.TODAY_IN_PARTY_HISTORY)
        .then((res) => {
          this.historyList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
    mounted() {
    let date = new Date();
    this.month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    this.day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  },
  };
</script>

<style scoped lang="scss">
    @import "@/themes/_handle.scss";

    .box {
        width: 14.4rem;
        height: 25.6rem;
        box-sizing: border-box;

        .header {
            display: flex;
            align-items: center;
            justify-content: left;
            padding: 1.2rem 0 1.35rem 1.1rem;

            img {
                width: 0.78rem;
                height: 0.78rem;
            }

            .today {
                font-size: 0.78rem;
                color: #AD0000;
                line-height: 0.78rem;
                margin-left: 0.3rem;
                font-weight: 600;
                letter-spacing: 0.15rem;
            }
        }

        .content {
            display: flex;
            justify-content: center;
            /*padding-top: 1.2rem;*/
            height: calc(100% - 3.39rem);
            box-sizing: border-box;
            overflow-y: scroll;

            .blcok-box {
                display: flex;

                .block-line {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-right: 0.24rem;

                    .danghui {
                        width: 0.42rem;
                        height: 0.42rem;
                        border-radius: 50%;
                        background: #9D2227;
                        text-align: center;

                        img {
                            margin-top: 0.08rem;
                            width: 0.22rem;
                            height: 0.22rem;
                        }
                    }

                    .line {
                        width: 0.06rem;
                        height: 100%;
                        background: linear-gradient(90deg, #FCF3A4 0%, #D89A25 100%);
                        box-shadow: 0 0.03rem 0.06rem rgba(91, 10, 10, 0.72);
                        opacity: 1;
                    }
                }

                .block {
                    .year {
                        text-align: left;
                        font-size: 0.36rem;
                        font-weight: 400;
                        color: #B40000;
                        text-shadow: 0 0.08rem 0.06rem rgba(0, 0, 0, 0.16);
                        margin-bottom: 0.1rem;
                    }

                    .blcok-content {
                        width: 12.93rem;
                        min-height: 3.29rem;
                        border: 0.05rem solid #E9C451;
                        box-shadow: 0 0.03rem 0.06rem rgba(91, 10, 10, 0.72);
                        opacity: 1;
                        border-radius: 0.05rem;
                        /*margin-top: 0.42rem;*/
                        margin-bottom: 0.45rem;
                        padding: 0.3rem;
                        box-sizing: border-box;
                        background-color: #ffffff;

                        &-title {
                            font-size: 0.24rem;
                            color: #333333;
                            text-align: left;
                            margin-bottom: 0.2rem;
                            font-weight: 600;
                        }

                        &-context {
                            font-size: 0.24rem;
                            line-height: 2;
                            color: #666666;
                            text-align: justify;
                        }
                    }
                }
            }
        }

        .calendar {
            width: 3.02rem;
            height: 3.13rem;
            position: fixed;
            top: 0.4rem;
            right: 0.6rem;

            .calendar-box {
                position: relative;
                width: 3.02rem;
                height: 3.13rem;

                img {
                    width: 100%;
                    height: 100%;
                }

                .time {
                    position: absolute;
                    top: 1.1rem;
                    left: 50%;
                    transform: translateX(-50%);
                    text-align: right;

                    .month, .day {
                        color: #CCA766;
                        font-size: 0.64rem;
                        line-height: 0.64rem;
                        font-weight: 600;
                    }

                    .day {
                        margin-top: 0.1rem;
                    }
                }
            }

        }
    }
</style>
