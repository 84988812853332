<template>
    <div class="box" :style="bg">
        <nav-router :routerList="routerList">
            <img :src="icon" alt="" style="width: 0.16rem;height: 0.16rem">
        </nav-router>
        <div class="content">
            <img src="@/assets/images/dangHistory/book-open.png" alt="">
            <div class="page1">
                {{page1Text}}
            </div>
            <div class="page2">
                {{page2Text}}
            </div>
            <div class="up">
                <img :src="up" alt="">
                上一页
            </div>
            <div class="down">
                <img :src="down" alt="">
                下一页
            </div>
        </div>
        <back :pageDepth="3"></back>
    </div>
</template>
<script>
  import navRouter from "@/components/navRouter";
  import back from "@/components/back";

  const redIcon = require("@/assets/images/dangHistory/home.png");
  const blueIcon = require("@/assets/images/dangHistory/home-blue.png");

  const blueBg = require("@/assets/images/index/bg-blue.png");
  const redBg = require("@/assets/images/index/bg.png");

  const redUp = require("@/assets/images/dangHistory/up.png");
  const redDown = require("@/assets/images/dangHistory/down.png");

  const blueUp = require("@/assets/images/dangHistory/up-blue.png");
  const blueDown = require("@/assets/images/dangHistory/down-blue.png");
  import { mapGetters } from "vuex";

  export default {
    name: "read",
    components: {
      navRouter,
      back
    },
    data() {
      return {
        icon: redIcon,
        up: redUp,
        down: redDown,
        bg: {
          backgroundImage: "url(" + redBg + ")"
        },
        bgRed: {
          backgroundImage: "url(" + redBg + ")"
        },
        bgBlue: {
          backgroundImage: "url(" + blueBg + ")"
        },
        routerList: [
          {
            name: "首页",
            path: "/layout/index"
          },
          {
            name: "党史馆",
            path: "/layout/dangHistory"
          },
          {
            name: "党的十八届三中全会",
            path: ""
          }
        ],
        //单页可容纳文字600字，请注意分割异步数据为600字一段
        page1Text: `                中国共产党第十八届中央委员会第三次全体会议（简称：中共十八届三中全会），于2013年11月9日至12日在北京召开。中共中央总书记习近平主持会议。主要议程是中共中央政治局向中央委员会报告工作，研究全面深化改革重大问题。会议共有中央委员204人、候补中央委员169人、中央纪律检查委员会常务委员会委员、有关方面负责同志、党的十八大代表中部分基层同志、专家学者出席。
                [1] 中央委员会总书记习近平作了重要讲话。
                中国共产党第十八届中央委员会第三次全体会议（简称：中共十八届三中全会），于2013年11月9日至12日在北京召开。
                中共中央总书记习近平主持会议。主要议程是中共中央政治局向中央委员会报告工作，研究全面深化改革重大问题。
                会议共有中央委员204人、候补中央委员169人、中央纪律检查委员会常务委员会委员、有关方面负责同志、党的十八大代表中部分基层同志、专家学者出席。 [1] 中央委员会总书记习近平作了重要讲话。
                会议共有中央委员204人、候补中央委员169人、中央纪律检查委员会常务委员会委员、有关方面负责同志党的十八大代表中部分基层同志、专家学者出席[1] 中央委员会总书记习近平作了重要讲话。`,
        page2Text: `                重大决议
                2013年11月12日中国共产党第十八届中央委员会第三次全体会议通过了《中共中央关于全面深化改革若干重大问题的决定》。
                决议中明确全面深化改革的五大体制改革要点：
                经济体制
                经济体制改革是全面深化改革的重点。其核心问题是如何处理好政府和市场的关系，使市场在资源配置中起决定性作用和更好地发挥政府作用； [5]
                坚持和完善基本经济制度，加快完善现代市场体系、宏观调控体系、开放型经济体系，加快转变经济发展方式，加快建设创新型国家，推动经济更有效率、更加公平、更可持续发展；
                以经济建设为中心，发挥经济体`
      };
    },
    computed: {
      ...mapGetters("skin", ["useSubject"])
    },
    created() {
      if (this.useSubject === "red") {
        this.icon = redIcon;
        this.bg = this.bgRed;
        this.up = redUp;
        this.down = redDown;
      } else {
        this.icon = blueIcon;
        this.bg = this.bgBlue;
        this.up = blueUp;
        this.down = blueDown;
      }
    },
    watch: {
      useSubject(val) {
        if (val === "red") {
          this.icon = redIcon;
          this.bg = this.bgRed;
          this.up = redUp;
          this.down = redDown;
        } else {
          this.icon = blueIcon;
          this.bg = this.bgBlue;
          this.up = blueUp;
          this.down = blueDown;
        }
      }
    },
    methods: {},
    mounted() {
    }
  };
</script>
<style scoped lang="scss">
    @import "@/themes/_handle.scss";

    .box {
        min-height: 24.8rem;
        overflow: hidden;

        .content {
            position: relative;
            width: 16.04rem;
            height: 12.24rem;
            font-size: 0.28rem;
            font-weight: bold;
            color: #555555;
            opacity: 1;
            margin: 0.6rem auto 0 auto;
            box-sizing: border-box;
            overflow: hidden;

            .page1 {
                position: absolute;
                top: 0.9rem;
                left: 1.26rem;
                width: 6rem;
                height: 5rem;
                text-align: justify;
                /*background-color: red;*/
                /*color: #ffffff;*/

            }

            .page2 {
                position: absolute;
                top: 0.9rem;
                right: 1.26rem;
                width: 6rem;
                height: 5rem;
                text-align: justify;
                /*background-color: red;*/
                /*color: #ffffff;*/

            }

            .up {
                position: absolute;
                left: 1.26rem;
                bottom: 1.6rem;
            }

            .down {
                position: absolute;
                right: 1.26rem;
                bottom: 1.6rem;
            }
        }
    }

</style>
