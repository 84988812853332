<template>
  <div class="box">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="content-right">
        <div class="content-top">
          <div class="video">
            <div id="mse"></div>
          </div>
          <div ref="tab" class="tab">
            <div class="video-list" ref="tabWrapper">
              <div
                :class="[
                  'video-item',
                  activeItem === index
                    ? 'video-item-active'
                    : 'video-item-blur',
                ]"
                ref="tabitem"
                v-for="(item, index) in chapterList"
                :key="item.id"
                @click="changeItem(item, index)"
              >
                <div class="play-img">
                  <img src="@/assets/images/classroom/play.png" alt="" />
                </div>
                <div class="info">
                  <!-- {{ item.name }} -->
                  <span class="info-title"
                    >第{{ index + 1 }}集 {{ item.name }}</span
                  >
                  <span class="info-minute">{{ item.durationMinute }}分钟</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="introduction">
          <div class="introduction-header">
            <span class="introduction-header-title">{{ course.name }}</span>
            <span class="introduction-header-time">
              总时长:{{ course.durationTotal }}
            </span>
          </div>
          <div class="introduction-info">
            <div class="introduction-info-title">课程介绍:</div>
            <div class="introduction-info-content">
              {{ course.remark }}
            </div>
          </div>
        </div>
      </div>
      <div class="signin-btn" @click="handleSign">
        <span>学员签到</span>
      </div>
    </div>
    <menu-pop :activeItem="3"></menu-pop>
    <back :pageDepth="5"></back>
  </div>
</template>

<script>
import menuPop from "@/components/menuPop";
import navRouter from "@/components/navRouter";
import Player from "xgplayer";
import back from "@/components/back";
import BScroll from "better-scroll";

const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "mine",
  components: {
    menuPop,
    navRouter,
    back
  },
  data() {
    return {
      icon: redIcon,
      activeItem: 0,
      chapterList: [],
      player: null,
      chapter: {},
      // 视频当前播放时间
      currentTime: "0",
      // 课程id
      contentId: "",
      showMore: false,
      course: {},
    };
  },
  methods: {
    ...mapMutations("skin", ["setRouterList"]),
    //大屏更新课程内容观看进度
    updateCurriculum() {
      // 将纯秒数 转化为 时:分:秒
      let value = localStorage.getItem('currentTime').split(".")[0]
      let result = parseInt(value)
      let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
      let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
      let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

      let res = '';
      res += `${h}:`;
      res += `${m}:`;
      res += `${s}`;
      this.POST(this.API_MODULE.UPDATE_CURRICULUM_CHAPTER, {
        contentId: this.contentId,
        studyDuration: res
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            console.log(res)
          } else {
            this.$message({ message: res.msg, type: "success" });
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    goBack() {
      this.updateCurriculum()
      this.$router.go(-1)
    },
    goHome() {
      this.updateCurriculum()
      this.$router.push({ path: '/layout/index' })
    },
    changeItem(item, index) {
      this.contentId = item.id
      this.activeItem = index;
      // 切换章节
      this.chapter = item;
      this.player.poster = item.picture ? this.OSS_URL + item.picture : '';

      // 切换视频源
      this.player.start(this.OSS_URL + item.pictureVideo);
      this.player.play()
    },
    initVideo() {
      let that = this
      this.player = new Player({
        id: "mse",
        // url: '',
        playsinline: true,
        whitelist: [""],
        width: "1680",
        height: "852",
        volume: 1,
        // poster: item.picture,
        keyShortcut: "on",
        autoplay: false,
        // "ignores": ["replay"],
        // fitVideoSize: 'auto',
        controlsList: ["nodownload", "nofullscreen", "noremoteplayback"],
        currentTime: that.currentTime,
        rotate: false,
        ignores: ['fullscreen'],
        cssFullscreen: true,
        lang: 'zh-cn',
      });
      this.player.on('timeupdate', function () {
        localStorage.setItem('currentTime', this.currentTime);
      })
      this.player.on('ended', function () {
        that.updateCurriculum()
      })

    },
    async init() {
      const res = await this.GET(this.API_MODULE.GET_COURSE_CHAPTERS, {
        id: this.$route.query.id,
      });

      this.chapterList = res.data;
      this.initVideo();
      this.changeItem(this.chapterList[0]);
      this.getCourse()
      this.$nextTick(() => {
        this.initScroll();
      });
    },

    handleSign() {
      this.$router.push({
        path: '/layout/signIn',
        query: { id: this.$route.query.id },
      })

      // 保存下一级路径
      const path = `/layout/signIn?id=${this.$route.query.id}`
      this.useRouterList.push({
        name: '学员签到',
        path: path
      })
      this.setRouterList(this.useRouterList, path)
    },
    initScroll() {
      let width = this.chapterList.length * 420;
      this.$refs.tabWrapper.style.width = ((width + 40) / 100) + "rem";
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BScroll(this.$refs.tab, {
            startX: 0,
            click: true,
            scrollX: true,
            scrollY: false,
            eventPassthrough: "vertical"
          });
        } else {
          this.scroll.refresh();
        }
      });
    },
    // 获取课程
    getCourse() {
      this.GET(this.API_MODULE.GET_COURSE, {
        id: this.$route.query.id,
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.course = res.data;
          } else {
            this.$message(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
  },
  computed: {
    ...mapGetters("skin", ["useSubject", "useRouterList"])
  },
  created() {
    if (this.useSubject === "red") {
      this.icon = redIcon;
    } else {
      this.icon = blueIcon;
    }

    this.init();
    this.useRouterList.find((router, i) => {
      if (!router) return
      if (router.path === this.$route.fullPath) {
        this.useRouterList.splice(i + 1)
      }
    })
    this.setRouterList(this.useRouterList, this.$route.fullPath)
  },
  watch: {
    useSubject(val) {
      if (val === "red") {
        this.icon = redIcon;
      } else {
        this.icon = blueIcon;
      }
    }
  },
  mounted() {
    this.initVideo();

  }
};
</script>

<style scoped lang="scss">
@import "@/themes/_handle.scss";

.box {
  .content {
    min-height: 24.2rem;
    box-sizing: border-box;
    @include background_color("background-medium");
    display: flex;
    .content-right {
      flex: 1;
      /*margin-left: 0.6rem;*/
      .content-top {
        @include background_color("border-light");
        padding: 0.4rem 0.4rem 0 0.4rem;

        .tab {
          width: 13.6rem;
          overflow: hidden;
          white-space: nowrap; /*当子元素超过父元素宽度的时候，不会折行*/
          padding: 0.6rem 0;
          .video-list {
            display: flex;
            .video-item {
              width: 4rem;
              height: 2.4rem;
              position: relative;
              display: flex;
              margin-right: 0.4rem;

              .play-img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                img {
                  width: 0.65rem;
                  height: 0.65rem;
                }
              }

              .info {
                position: absolute;
                bottom: 0.1rem;
                left: 0;
                right: 0;
                display: flex;
                justify-content: space-between;
                padding: 0 0.3rem;
                font-size: 0.24rem;
                color: #ffffff;

                .info-title {
                  white-space: pre-line;
                  flex: 7;
                }
                .info-minute {
                  padding-left: 0.3rem;
                  flex: 1;
                }
              }
            }

            .video-item-blur {
              @include background_color("background-medium");
            }

            .video-item-active {
              background-color: #cca766;
            }
          }
        }
      }

      .title {
        display: flex;
        justify-content: space-between;
        font-size: 0.48rem;
        color: #ffffff;
        line-height: 0.48rem;
        margin-bottom: 0.4rem;
        margin-top: 0.6rem;

        .time {
          font-size: 0.36rem;
        }
      }

      .video {
        width: 13.6rem;
        height: 7.86rem;
        /*margin-top: 0.58rem;*/
      }

      .introduction {
        width: 13.6rem;
        height: 5.2rem;
        overflow-x: hidden;
        overflow-y: scroll;
        background: #ffffff;
        opacity: 0.8;
        border-radius: 0.05rem;
        padding: 0.4rem;
        box-sizing: border-box;
        margin-top: 0.4rem;
        margin-left: 0.4rem;
        &-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include font_color("font-medium");

          &-title {
            font-size: 0.42rem;
            font-weight: bold;
            opacity: 1;
          }

          &-time {
            font-size: 0.24rem;
            font-weight: 400;
            opacity: 1;
          }
        }
      }

      .introduction-info {
        @include font_color("font-medium");
        text-align: justify;
        margin-top: 0.3rem;

        &-title,
        &-content {
          text-align: justify;
          font-size: 0.28rem;
          opacity: 1;
        }
      }
    }
    .signin-btn {
      width: 13.6rem;
      height: 1.8rem;
      background-color: #cca766;
      position: fixed;
      left: 0.4rem;
      bottom: 1.2rem;
      line-height: 1.8rem;
      color: #ffffff;
      font-size: 0.48rem;
      text-align: center;
    }
  }
}
</style>
