<template>
  <div class="box" :style="bg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <!--<div class="left-column">-->
      <!--<div class="left-row" v-for="(item, index) in 3" :key="index">-->
      <!--<div class="avatar">-->
      <!--<img src="@/assets/images/interactive/2.png" alt="" />-->
      <!--</div>-->
      <!--<div class="info">-->
      <!--<div class="info-title">-->
      <!--<div class="info-title-name">-->
      <!--张三/ <span class="info-title-name-position">处长</span>-->
      <!--</div>-->
      <!--<img src="@/assets/images/interactive/vote-1.png" alt="" />-->
      <!--</div>-->

      <!--<div class="info-number">-->
      <!--<span>获得票数</span>-->
      <!--<span>456票</span>-->
      <!--</div>-->
      <!--<div>-->
      <!--<el-progress-->
      <!--:text-inside="true"-->
      <!--:stroke-width="20"-->
      <!--:percentage="50"-->
      <!--status="exception"-->
      <!--&gt;</el-progress>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <div class="right-column">
        <div class="left-row" v-for="(item, index) in rightList" :key="item.id">
          <div class="avatar">
            <img
              :src="`${OSS_URL}/${item.relativePath}/${item.filename}`"
              alt=""
            />
          </div>
          <div class="info">
            <div class="info-title">
              <div class="info-title-name">
                {{ item.name }}/
                <span class="info-title-name-position">{{ item.post }}</span>
              </div>
              <img
                src="@/assets/images/interactive/vote-1.png"
                v-if="index === 0"
                alt=""
              />
              <img
                src="@/assets/images/interactive/vote-2.png"
                v-else-if="index === 1"
                alt=""
              />
              <img
                src="@/assets/images/interactive/vote-3.png"
                v-else-if="index === 2"
                alt=""
              />
              <div class="rank-number" v-else>
                <span>{{ index + 1 }}</span>
              </div>
            </div>
            <div>
              <div class="info-number">
                <span>获得票数</span>
                <span>{{ item.voteNum }}票</span>
              </div>
              <div class="info-progress">
                <el-progress
                  :text-inside="true"
                  :stroke-width="20"
                  :percentage="(item.voteNum / item.totalNum) | formatToFixed"
                  status="exception"
                ></el-progress>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <menu-pop :activeItem="3"></menu-pop>
    <back :pageDepth="4"></back>
  </div>
</template>

<script>
import menuPop from "@/components/menuPop";
import back from "@/components/back";
import navRouter from "@/components/navRouter";

const redIcon = require("@/assets/images/classroom/icon.png");
const blueIcon = require("@/assets/images/classroom/icon-blue.png");
import { mapGetters } from "vuex";

export default {
  name: "VurResult",
  components: {
    menuPop,
    navRouter,
    back
  },
  data() {
    return {
      icon: redIcon,
      bg: {
        backgroundColor: "#E03838"
      },
      bgRed: {
        backgroundColor: "#E03838"
      },
      bgBlue: {
        backgroundColor: "#2070DB"
      },
      rightList: []
    };
  },
  methods: {
    getData() {
      this.GET(this.API_MODULE.GET_VOTE_RESULT, { id: this.$route.query.id }).then(res => {
        this.rightList = res.data
      }).catch(err => {
        console.log(err)
      })
    }
  },
  computed: {
    ...mapGetters("skin", ["useSubject", "useRouterList"])
  },
  created() {
    if (this.useSubject === "red") {
      this.icon = redIcon;
      this.bg = this.bgRed
    } else {
      this.icon = blueIcon;
      this.bg = this.bgBlue
    }
    this.getData()
  },
  watch: {
    useSubject(val) {
      if (val === "red") {
        this.icon = redIcon;
        this.bg = this.bgRed
      } else {
        this.icon = blueIcon;
        this.bg = this.bgBlue
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/themes/_handle.scss";

.box {
  min-height: 25.6rem;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 0.6rem;
  box-sizing: border-box;
  .content {
    margin-top: 0.6rem;
    /*display: flex;*/
    .left-column {
      flex: 1;
      display: flex;
      flex-direction: column;

      .left-row {
        display: flex;
        margin-top: 0.6rem;
        margin-left: 1.2rem;

        .avatar {
          width: 3rem;
          height: 3.5rem;
          border: 0.1rem solid #ffffff;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .info {
          flex-basis: 80%;
          margin-left: 0.4rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          &-title {
            display: flex;
            justify-content: space-between;
            padding-bottom: 1.2rem;

            &-name {
              font-size: 0.48rem;
              color: #ffffff;
              flex: 9;
              &-position {
                font-size: 0.36rem;
              }
            }
          }

          &-number {
            display: flex;
            justify-content: space-between;
            font-size: 0.3rem;
            color: #ffffff;
          }
        }
      }
    }

    .right-column {
      /*flex: 1;*/
      /*margin-left: 1.55rem;*/
      /*margin-right: 2.8rem;*/
      display: flex;
      flex-direction: column;
      padding: 0 0.4rem;

      .left-row {
        display: flex;
        margin-top: 0.6rem;
        /*margin-left: 1.2rem;*/
        .avatar {
          width: 3rem;
          height: 3.5rem;
          border: 0.1rem solid #ffffff;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .info {
          /*flex-basis: 80%;*/
          flex: 1;
          margin-left: 0.45rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          &-title {
            display: flex;
            justify-content: space-between;
            padding-bottom: 0.44rem;
            align-items: center;

            &-name {
              font-size: 0.4rem;
              color: #ffffff;

              &-position {
                font-size: 0.36rem;
              }
            }

            .rank-number {
              width: 0.8rem;
              height: 0.8rem;
              border-radius: 50%;
              @include background_color("background-normal");
              font-size: 0.6rem;
              color: #ffffff;

              span {
                display: inline-block;
                padding-top: 0.1rem;
              }
            }
          }

          &-number {
            display: flex;
            justify-content: space-between;
            font-size: 0.3rem;
            color: #ffffff;
            margin-bottom: 0.3rem;
          }

          &-progress ::v-deep .el-progress-bar__inner {
            @include background-color("background-progress");
          }
        }
      }
    }
  }
}
</style>
