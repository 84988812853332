<template>
  <div class="box" :style="bg">
    <div>
      <!--轮播图-->
      <el-carousel height="7.8rem" style="width: 13.6rem" :autoplay="true" :interval="5000">
        <el-carousel-item v-for="item in slideShow" :key="item.id">
          <img :src="OSS_URL + item.picture" alt="" class="banner-img" />
        </el-carousel-item>
      </el-carousel>
      <!--下方可滚动的菜单区域-->
      <div class="line">
        <el-carousel height="2.7rem" style="width: 11.6rem" :autoplay="false" indicator-position="none" ref="banner" arrow="never">
          <el-carousel-item v-for="(carousel, index) in Math.ceil(appDOWN.length / 6)" :key="carousel">
            <div class="left-list">
              <div v-for="item in appDOWN.slice(index * 6, index * 6 + 6)" :key="item.id" @click="handleDown(item)">
                <img :src="`${OSS_URL}/${item.iconName}`" alt="" />
                <p class="name">{{ item.nameCustom }}</p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="curse-left" @click="prev">
          <img src="@/assets/images/index/left.png" alt="" />
        </div>
        <div class="curse-right" @click="next">
          <img src="@/assets/images/index/right.png" alt="" />
        </div>
      </div>
      <!--右侧菜单区域-->
      <div class="list">
        <div class="item" v-for="item in appRIGHT" :key="item.code" @click="handleRight(item)">
          <img :src="`${OSS_URL}/${item.iconName}`" alt="" class="icon-right" />
          <p class="name">{{ item.nameCustom }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { openUrl } from '@/utils/windowOpen'
const blueBg = require('@/assets/images/index/bg-blue.png')
const redBg = require('@/assets/images/index/bg.png')
export default {
  name: 'index',
  data() {
    return {
      bg: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgRed: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgBlue: {
        backgroundImage: 'url(' + blueBg + ')'
      },
      slideShow: [],
      appDOWN: [],
      appRIGHT: []
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.bg = this.bgRed
    } else {
      this.bg = this.bgBlue
    }
    this.getCarouselImages()
    this.getApps()
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.bg = this.bgRed
      } else {
        this.bg = this.bgBlue
      }
    }
  },
  methods: {
    prev() {
      this.$refs.banner.prev()
    },
    next() {
      console.log(1)
      this.$refs.banner.next()
    },
    // 获取轮播图
    getCarouselImages() {
      this.POST(this.API_MODULE.CAROUSEL_PICTURES)
        .then((res) => {
          this.slideShow = res.data.splice(0, 15)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取滚动列表
    getApps() {
      this.POST(this.API_MODULE.HOME_APPLICATION)
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.appDOWN = res.data.appDOWN
            this.appRIGHT = res.data.appRIGHT
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    handleDown(item) {
      console.log(item)
      if (['VOW_WALL', 'RED_BASE', 'PARTY_LECTURE_JOURNAL'].includes(item.code)) {
        this.$router.push({ path: `/layout/app/${item.code}/${item.id}` })
      } else {
        if (item.isExternalLinks === 0) {
          this.$router.push({ path: `/layout/customize`, query: { appId: item.id } })
        } else {
          openUrl(item.externalLinksUrl)
        }
      }
    },
    handleRight(item) {
      // 此版本不开放智慧互动的功能
      if (item.code === 'WISDOM_INTERACTION') {
        // return this.$alert('此功能暂未开放！', '提示', {
        //   confirmButtonText: '确定'
        // })
        return this.$router.push({ path: `/layout/interactive` })
      }
      this.$router.push({ path: `/layout/app/${item.code}/${item.id}` })
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/themes/_handle.scss';

.box {
  /*min-height: calc(100vh - 0.8rem);*/
  min-height: 24.8rem;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 0.4rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .banner-img {
    width: 15.4rem;
    height: 8.8rem;
  }

  .line {
    width: 13.6rem;
    height: 2.8rem;
    background-color: rgba(255, 255, 255, 0.8);
    border-style: solid;
    border-width: 0.05rem;
    @include border_color('border-witer');
    border-radius: 0.05rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin-top: 0.6rem;
    position: relative;

    .left-list {
      display: flex;
      align-items: center;
      justify-content: left;
      height: 2.7rem;
      opacity: 1;
      div {
        flex: 0 0 auto;
        width: 16.66%;
        opacity: 1;
        cursor: pointer;
        .name {
          @include font_color('font-medium');
          font-size: 0.3rem;
        }
      }
      img {
        width: 1.2rem;
      }
    }

    .curse-left,
    .curse-right {
      width: 0.3rem;
      height: 1.6rem;
      @include background_color('background-normal');
      opacity: 0.5;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .curse-left {
      position: absolute;
      left: 0.36rem;
      top: 50%;
      transform: translateY(-50%);
    }

    .curse-right {
      position: absolute;
      right: 0.36rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .list {
    /*width: 8.4rem;*/
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0.8rem;
    .item {
      width: 3.1rem;
      height: 4.3rem;
      background-color: rgba(255, 255, 255, 0.8);
      border-style: solid;
      border-width: 0.05rem;
      @include border_color('border-witer');
      border-radius: 0.1rem;
      margin-bottom: 0.4rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 0.9rem;
        height: 0.9rem;
        margin-bottom: 0.7rem;
      }

      .name {
        font-size: 0.4rem;
        @include font_color('font-medium');
      }
    }
  }
}
</style>
<style lang="scss">
@import '@/themes/_handle.scss';

.el-carousel__button {
  width: 0.1rem;
  height: 0.1rem;
  @include background_color('background-witer');
  border-radius: 50%;
  opacity: 1;
}

.el-carousel__indicator.is-active button {
  width: 0.3rem;
  height: 0.1rem;
  border-radius: 0.1rem;
  @include background_color('background-btn');
}

.el-carousel__indicators--horizontal {
  left: 11.5rem;
  bottom: 0.3rem;
  background: #000000;
  opacity: 0.6;
  border-radius: 0.05rem;
  white-space: nowrap;
}
</style>
