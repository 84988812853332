<template>
  <div class="box">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="content-top">
        <div class="content-top-video">
          <div id="mse"></div>
        </div>
        <div class="content-top-right">
          <div class="introduction">
            <div class="introduction-header">
              <span class="introduction-header-title">
                {{ course.name ? course.name : " " }}
              </span>
              <span class="introduction-header-time">
                总时长:{{ course.durationTotal }}
              </span>
            </div>
            <div class="introduction-info">
              <div class="introduction-info-title">课程介绍:</div>
              <div class="introduction-info-content">
                {{ course.remark }}
              </div>
            </div>
          </div>
          <div class="content-top-btns">
            <div class="start" @click="handleStart(course)">
              <span v-if="isContinue != 0">继续学习（{{ isContinue }}%）</span>
              <span v-else>开始学习</span>
            </div>
            <div class="star" @click="collect !== 1 ? collectCourse() : cancelCollect()">
              <span>{{ collect !== 1 ? "收藏" : "取消收藏" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content-footer">
        <div class="title">相关推荐</div>
        <div class="course-list">
          <div class="course-item" v-for="course in recommendList" :key="course.id" @click="linkToCurrentPage(course)">
            <div class="cover">
              <img :src="`${OSS_URL}${course.pictureVertical}`" alt="" />
            </div>
            <div class="course-introduction">
              <p class="course-name">{{ course.name }}</p>
              <div class="course-percentage">
                <el-progress :percentage="
                    parseInt(course.percentage ? course.percentage : 0)
                  " :show-text="false" :stroke-width="14"></el-progress>
              </div>
              <div class="course-footer">
                <p>总时长:{{ course.duration }}</p>
                <p>已学习{{ course.percentage ? course.percentage : 0 }}%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <back :pageDepth="4"></back>
  </div>
</template>

<script>
import Player from 'xgplayer'
import navRouter from '@/components/navRouter'
import back from '@/components/back'
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
const blueBg = require('@/assets/images/index/bg-blue.png')
const redBg = require('@/assets/images/index/bg.png')
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'info',
  inject: ['reload'],
  components: {
    navRouter,
    back
  },
  data() {
    return {
      icon: redIcon,
      bg: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgRed: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgBlue: {
        backgroundImage: 'url(' + blueBg + ')'
      },
      title: '',
      player: null,

      recommendList: [],
      course: {},
      collect: null,
      showMore: false,
      isContinue: 0,
      columnId: ''
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    this.columnId = this.$route.query.columnId
    if (this.useSubject === 'red') {
      this.icon = redIcon
      // this.bg = this.bgRed
    } else {
      this.icon = blueIcon
      // this.bg = this.bgBlue
    }

    this.getCourse()

    this.useRouterList.find((router, i) => {
      if (!router) return
      if (router.path === this.$route.fullPath) {
        this.useRouterList.splice(i + 1)
      }
    })
    this.setRouterList(this.useRouterList, this.$route.fullPath)
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
        this.bg = this.bgRed
      } else {
        this.icon = blueIcon
        this.bg = this.bgBlue
      }
    }
  },
  methods: {
    ...mapMutations('skin', ['setRouterList']),
    goBack() {
      // console.log(this.$route.query.directoryId)
      // directory.vue中根据goByDirectoryId是否为1，来判断需不需要跳转到相应的栏目下
      localStorage.setItem('goByDirectoryId', '1')
      this.$router.push({
        path: '/layout/directory',
        query: { id: this.columnId }
      })
    },
    initVideo() {
      this.player = new Player({
        id: 'mse',
        url: this.OSS_URL + this.course.video,
        playsinline: true,
        whitelist: [''],
        width: '1280',
        height: '740',
        volume: 1,
        poster: this.OSS_URL + this.course.pictureVertical,
        keyShortcut: 'on',
        rotate: false,
        ignores: ['fullscreen'],
        cssFullscreen: true,
        download: true,
        lang: 'zh-cn',
        // "ignores": ["replay"],
        // fitVideoSize: 'auto',
        // "http://5b0988e595225.cdn.sohucs.com/images/20200427/4d3a5cd37aaf40ae9689f00ab4339402.jpeg",

        controlsList: ['nodownload', 'nofullscreen', 'noremoteplayback']
      })
    },

    // 获取课程
    getCourse() {
      this.GET(this.API_MODULE.GET_COURSE, {
        id: this.$route.params.id
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.course = res.data
            // 设置是否收藏状态
            this.collect = this.course.isCollect
            this.initVideo()
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    // 获取相关课程
    getRelatedCourses() {
      this.GET(this.API_MODULE.GET_RELATED_COURSES, {
        id: this.$route.params.id
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.recommendList = res.data
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    // 收藏课程
    collectCourse() {
      this.POST(this.API_MODULE.COLLECT_COURSE, {
        categoryId: this.course.id
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.collect = 1
            this.$message({ message: '收藏成功！', type: 'success' })
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    // 取消收藏
    cancelCollect() {
      this.DELETE(this.API_MODULE.CANCEL_COLLECT, { params: { id: this.course.id } })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.collect = 0
            this.$message({ message: '取消收藏', type: 'success' })
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    linkToCurrentPage(current) {
      // 跳转前清除原课程信息数据
      // this.course = null
      this.$router.push({
        path: `/layout/learn/${current.id}`,
        query: { directoryId: current.parentId, percentage: current.percentage, columnId: this.columnId }
      })
      this.$nextTick(() => this.reload())

      this.useRouterList.find((router, i) => {
        if (!router) return
        if (router.path === this.$route.fullPath) {
          this.useRouterList.splice(i + 1)
        }
      })
      this.setRouterList(this.useRouterList, this.$route.fullPath)
    },
    handleStart(course) {
      this.$router.push({
        path: '/layout/videoPlayMode',
        query: { id: course.id }
      })

      // 保存下一级路径
      const path = `/layout/videoPlayMode?id=${course.id}`
      this.useRouterList.push({
        name: course.name,
        path: path
      })
      this.setRouterList(this.useRouterList, path)
    }
  },
  mounted() {
    this.title = this.$route.query.title
    this.getRelatedCourses()
    // 判断是显示继续学习还是开始学习
    if (this.$route.query.percentage && this.$route.query.percentage != 0) {
      this.isContinue = this.$route.query.percentage
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/themes/_handle.scss';

.box {
  min-height: 25.6rem;
  background-size: cover;
  background-repeat: no-repeat;
  .router-nav {
    width: 100%;
    height: 0.6rem;
    background-color: #ffffff;
    padding-left: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .nav-left {
      display: flex;

      .router-name {
        font-size: 0.16rem;
        @include font_color('font-medium');
        margin-left: 0.1rem;

        .sub-router {
          font-size: 0.16rem;
          color: #cca766;
        }
      }
    }
  }

  .content {
    min-height: 24.2rem;
    padding: 0.4rem 0 0 0.4rem;
    box-sizing: border-box;
    @include background_color('background-medium');

    .content-top {
      /*display: flex;*/

      &-video {
        width: 13.6rem;
        height: 7.86rem;
      }

      &-right {
        /*flex: 1;*/
        /*margin-left: 0.45rem;*/
        .introduction {
          width: 13.6rem;
          height: 4.2rem;
          overflow-x: hidden;
          overflow-y: scroll;
          background: #ffffff;
          opacity: 0.8;
          border-radius: 0.05rem;
          padding: 0.4rem;
          box-sizing: border-box;
          margin-top: 0.4rem;
          &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include font_color('font-medium');

            &-title {
              font-size: 0.42rem;
              font-weight: bold;
              opacity: 1;
            }

            &-time {
              font-size: 0.24rem;
              font-weight: 400;
              opacity: 1;
            }
          }
        }

        .introduction-info {
          @include font_color('font-medium');
          text-align: justify;
          margin-top: 0.3rem;

          &-title,
          &-content {
            text-align: justify;
            font-size: 0.28rem;
            opacity: 1;
          }
        }

        .content-top-btns {
          display: flex;
          margin-top: 0.4rem;

          .start {
            margin-right: 0.4rem;
          }

          .start,
          .star {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 6.6rem;
            height: 1.8rem;
            background: #cca766;
            border-radius: 0.05rem;
            font-size: 0.31rem;
            color: #ffffff;
          }
        }
      }
    }

    .content-footer {
      .title {
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
        text-align: left;
        color: #ffffff;
        font-size: 0.3rem;
      }
      .course-list {
        display: flex;
        flex-wrap: wrap;
        flex: 1;

        .course-item {
          width: 6.6rem;
          height: 6.7rem;
          border-style: solid;
          border-width: 0.1rem;
          @include border_color('border-witer');
          border-radius: 0.05rem;
          position: relative;
          margin-right: 0.4rem;
          margin-bottom: 0.4rem;
          box-sizing: border-box;

          .cover {
            width: 6.4rem;
            height: 6.5rem;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .course-introduction {
            padding: 0.56rem 0.18rem 0.18rem 0.18rem;
            box-sizing: border-box;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2.1rem;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 0 0 0.05rem 0.05rem;

            .course-name {
              font-size: 0.39rem;
              line-height: 0.39rem;
              font-weight: bold;
              color: #ffffff;
              margin-bottom: 0.3rem;
              text-align: left;
              width: 3.6rem;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .course-percentage {
              margin-bottom: 0.25rem;
            }

            .course-footer {
              display: flex;
              justify-content: space-between;
              font-size: 0.22rem;
              color: #ffffff;
            }
          }

          ::v-deep .el-progress-bar__inner {
            @include background_color('background-medium');
          }
        }
      }
    }
  }
}
</style>
