<template>
  <div class="box" :style="bg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>

    <div class="content">
      <div class="content-left">
        <!--<div class="qrcode-container">-->
        <!--<vue-qrcode tag="img" value="Hello, World!" class="qrcode" />-->
        <!--</div>-->
        <div class="content-left-button">
          <el-button @click="getData">
            <img src="@/assets/images/interactive/reload.png" alt="" />
            刷新
          </el-button>
          <el-button
            v-if="statistic && statistic.examStatus == 2"
            @click="answerEnd"
          >
            <img src="@/assets/images/interactive/read.png" alt="" />
            结束答题
          </el-button>
          <el-button @click="showModal = true">
            <img src="@/assets/images/interactive/qrcode.png" alt="" />
            答题
          </el-button>
        </div>
        <div class="content-left-exam-info">
          <div class="content-left-column">
            <p>
              成员人数:{{
                statistic ? (statistic.totalNum ? statistic.totalNum : 0) : 0
              }}
            </p>
            <p class="ing">
              答题中人数:{{
                statistic
                  ? statistic.beBeingNum
                    ? statistic.beBeingNum
                    : 0
                  : 0
              }}
            </p>
            <p>
              已交卷人数:{{
                statistic
                  ? statistic.achieveNum
                    ? statistic.achieveNum
                    : 0
                  : 0
              }}
            </p>
          </div>
        </div>
      </div>

      <div class="content-right">
        <div class="status">
          <div class="status-color-block-green"></div>
          <span>答题中</span>
          <div class="status-color-block-red"></div>
          <span>已交卷</span>
        </div>
        <div class="answer-list">
          <div class="answer-img" v-for="item in answerers" :key="item.id">
            <img :src="item.wxAvatarUrl" alt="头像" />
            <div class="answer-info">
              <div>{{ item.wxName }}</div>
              <div
                class="status-color-block-green"
                v-if="item.examType == 0"
              ></div>
              <div class="status-color-block-red" v-else></div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isEnd" class="content-ranking">
        <div class="top">
          <div class="top-two" v-if="topTwo">
            <img class="top-two-avatar" :src="topTwo.wxAvatarUrl" alt="" />
            <img
              class="top-two-silver-medal"
              src="@/assets/images/interactive/top-2.png"
              alt=""
            />

            <p class="name">{{ topTwo.wxName }}</p>
            <p class="score">
              {{ topTwo.elapsedTime }}分钟/{{ topThree.correctNum }}
            </p>
          </div>
          <div class="top-one" v-if="topOne">
            <img class="top-one-avatar" :src="topOne.wxAvatarUrl" alt="" />
            <img
              class="top-one-gold-medal"
              src="@/assets/images/interactive/top-1.png"
              alt=""
            />
            <p class="name">{{ topOne.wxName }}</p>
            <p class="score">
              {{ topTwo.elapsedTime }}分钟/{{ topThree.correctNum }}
            </p>
          </div>
          <div class="top-three" v-if="topThree">
            <img class="top-three-avatar" :src="topThree.wxAvatarUrl" alt="" />
            <img
              class="top-three-bronze-medal"
              src="@/assets/images/interactive/top-3.png"
              alt=""
            />
            <p class="name">{{ topThree.wxName }}</p>
            <p class="score">
              {{ topTwo.elapsedTime }}分钟/{{ topThree.correctNum }}
            </p>
          </div>
        </div>
        <div class="top-three-after" v-if="leaderboard.length > 3">
          <div class="top-three-after-row">
            <div style="color: #b40000">排名</div>
            <div>头像</div>
            <div>名称</div>
            <div>耗时</div>
            <div style="color: #67c23a">答对题数</div>
          </div>
          <div
            v-for="(item, index) in leaderboard.slice(3)"
            :key="item.id"
            class="top-three-after-row"
          >
            <div style="color: #b40000">{{ index + 4 }}</div>
            <div>
              <img style="border-radius: 100%" :src="item.wxAvatarUrl" alt="" />
            </div>
            <div>{{ item.wxName }}</div>
            <div>{{ item.elapsedTime }}分钟</div>
            <div>{{ item.correctNum }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="showModal" @click.stop="showModal = false">
      <div class="qrcode">
        <vue-qrcode
          tag="img"
          :value="`${WX_URL}/answer?ansId=${answerId}&userId=${userId}`"
        />
        <p>微信扫码答题</p>
      </div>
    </div>
    <back :pageDepth="3"></back>
    <menu-pop :activeItem="3"></menu-pop>
  </div>
</template>

<script>
import menuPop from '@/components/menuPop'
import navRouter from '@/components/navRouter'
import back from '@/components/back'
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
import { mapGetters } from 'vuex'
import vueQrcode from '@chenfengyuan/vue-qrcode'

export default {
  name: 'Answer',
  components: {
    menuPop,
    navRouter,
    vueQrcode,
    back
  },
  data() {
    return {
      icon: redIcon,
      bg: {
        backgroundColor: '#E03838'
      },
      bgRed: {
        backgroundColor: '#E03838'
      },
      bgBlue: {
        backgroundColor: '#2070DB'
      },
      isEnd: true,
      showModal: false,
      nswerId: undefined,
      userId: undefined,
      // 统计
      statistic: {},
      answerers: [],
      leaderboard: [],
      topOne: {},
      topTwo: {},
      topThree: {}
    }
  },
  methods: {
    getData() {
      this.GET(this.API_MODULE.GET_EXAM_STATUS_DATA, {
        id: this.answerId
      }).then((res) => {
        this.statistic = res.data.statisticsUserExam || {}
        this.answerers = res.data.userList
        this.leaderboard = res.data.ranking
        this.topOne = res.data.ranking.slice(0, 1)[0]
        this.topTwo = res.data.ranking.slice(1, 2)[0]
        this.topThree = res.data.ranking.slice(2, 3)[0]
        this.leaderboard.length !== 0 ? (this.isEnd = true) : (this.isEnd = false)
      })
    },

    answerEnd() {
      this.POST(this.API_MODULE.ANSWER_END, { id: this.answerId })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.getData()
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$message(err.msg)
        })
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
      this.bg = this.bgRed
    } else {
      this.icon = blueIcon
      this.bg = this.bgBlue
    }

    this.answerId = this.$route.query.id

    const user = JSON.parse(window.localStorage.getItem('USER'))
    this.userId = user.id
    this.getData()
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
        this.bg = this.bgRed
      } else {
        this.icon = blueIcon
        this.bg = this.bgBlue
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    to.meta.keepAlive = true
    next()
  },
}
</script>

<style lang="scss" scoped>
@import "@/themes/_handle.scss";

.box {
  background-size: cover;
  background-repeat: no-repeat;

  .content {
    min-height: 24.2rem;
    margin-top: 0.6rem;
    padding-bottom: 0.6rem;
    box-sizing: border-box;

    .content-left {
      flex: 1;
      margin: 0 0.4rem;

      .qrcode-container {
        width: 7rem;
        height: 4.8rem;
        background-color: #ffffff;
        border: 0.1rem solid rgba(180, 0, 0, 0.4);

        .qrcode {
          width: 4.22rem;
          height: 4.22rem;
          margin-top: 0.2rem;
        }
      }

      .content-left-button {
        margin-top: 0.4rem;
        display: flex;
        justify-content: space-between;

        button {
          width: 4.26rem;
          height: 1.8rem;
          background: #cca766;
          opacity: 1;
          border-radius: 0.05rem;
          font-size: 0.31rem;
          color: #ffffff;
          text-align: center;
          border: 1px solid #cca766;
        }
      }

      .content-left-exam-info {
        width: 13.6rem;
        height: 3.3rem;
        background-color: #ffffff;
        @include border_color("border-light");
        border-style: solid;
        border-width: 0.1rem;
        margin-top: 0.4rem;
        box-sizing: border-box;

        .content-left-column {
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: 0 1.2rem;
        }

        p {
          width: 50%;
          /*color: #b40000;*/
          @include font_color("font-medium");
          font-size: 0.36rem;
          text-align: left;
          /*margin-top: 0.9rem;*/
        }

        .ing {
          text-align: right;
        }
      }
    }

    .content-right {
      margin: 0.6rem 0.4rem;
      flex: 4;
      background-color: #ffffff;
      @include border_color("border-light");
      border-style: solid;
      border-width: 0.1rem;
      overflow: auto;

      .status {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include font_color("font-medium");
        font-size: 0.36rem;
        margin-top: 0.6rem;
        padding-right: 0.2rem;

        span + span {
          /*margin-left: 0.68rem;*/
        }

        span {
          /*display: inline-block;*/
          margin-left: 0.18rem;
          margin-right: 0.4rem;
        }
      }

      .answer-list {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;

        .answer-img {
          margin-bottom: 0.8rem;
          margin-left: 0.8rem;

          img {
            height: 2.4rem;
            width: 2.4rem;
            border-radius: 0.1rem;
          }
        }

        .answer-info {
          display: flex;
          justify-content: space-between;
          color: #555555;
          font-size: 0.36rem;
          padding: 0 0.2rem;
          margin-top: 0.2rem;
        }
      }
    }

    .content-ranking {
      width: 13.6rem;
      margin-left: 0.4rem;
      /*flex: 4;*/
      background-color: #ffffff;
      @include border_color("border-light");
      border-style: solid;
      border-width: 0.1rem;
      overflow: auto;
      box-sizing: border-box;
      padding-bottom: 0.4rem;

      .top {
        display: flex;
        justify-content: space-evenly;

        .name {
          color: #000000;
          font-size: 0.3rem;
        }

        .score {
          @include font_color("font-medium");
          font-size: 0.24rem;
        }

        &-two {
          position: relative;

          &-avatar {
            position: absolute;
            width: 1.1rem;
            height: 1.1rem;
            border-radius: 100%;
            top: 1.35rem;
            left: 0.695rem;
          }

          &-silver-medal {
            margin-top: 0.96rem;
          }
        }

        &-one {
          position: relative;

          &-avatar {
            position: absolute;
            width: 1.46rem;
            height: 1.46rem;
            border-radius: 100%;
            top: 1rem;
            left: 0.775rem;
          }

          &-gold-medal {
            margin-top: 0.69rem;
          }
        }

        &-three {
          position: relative;

          &-avatar {
            position: absolute;
            width: 1.1rem;
            height: 1.1rem;
            border-radius: 100%;
            top: 1.4rem;
            left: 0.6rem;
          }

          &-bronze-medal {
            margin-top: 1.12rem;
          }
        }
      }

      .top-three-after {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        font-size: 0.28rem;
        color: #555555;

        &-row {
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-top: 0.8rem;
        }
      }
    }
  }

  .status-color-block-red {
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    @include background_color("background-medium");
  }

  .status-color-block-green {
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    background-color: #16a510;
  }

  .top-three-after-row {
    margin-top: 1.1rem !important;
  }

  .top-three-after-row > * {
    flex: 1;
  }

  .modal {
    position: fixed;
    top: 1.4rem;
    left: 0;
    right: 0;
    bottom: 0;
    @include background_color("background-high-8");
    .qrcode {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.56rem;
      color: #ffffff;

      img {
        width: 8rem;
      }
    }
  }
}
</style>
